<template>
<div v-if="word && word.type">
  <div class="card amber lighten-5 left-align" >
    <div class="card-content">
      <span class="card-title"> {{ getWordTitle(word) }} <i style="font-size: 0.8em;" class="blue-grey-text text-darken-1"> {{ word.type }} </i></span>

<!--      verb view-->
      <div v-if="word.type === 'verb'" style="font-size: 0.8em">
        <p><span class="blue-text text-accent-2 text-darken-4">Infinitive Form:</span> {{word.data.infinitive}} {{word.data.suffixSentence}} </p>
        <p><span class="blue-text text-accent-2 text-darken-4">Past Form:</span> {{word.data.past}} {{word.data.suffixSentence}} </p>
        <p><span class="blue-text text-accent-2 text-darken-4">Participle Form: </span> {{word.data.perfect}} {{word.data.suffixSentence}} / {{word.data.perfectFeminine}} {{word.data.suffixSentence}}  </p>
      </div>
      <!--      noun view-->
      <div v-if="word.type === 'noun'" style="font-size: 0.8em">
        <p><span class="blue-text text-accent-2 text-darken-4">Singular:</span> {{word.data.singular}}  </p>
        <p><span class="blue-text text-accent-2 text-darken-4">Plural:</span> {{word.data.plural}}  </p>
      </div>
      <!--      adjective view-->
      <div v-if="word.type === 'adjective'" style="font-size: 0.8em">
        <p><span class="blue-text text-accent-2 text-darken-4">Singular:</span> {{word.data.masculine}}  </p>
        <p><span class="blue-text text-accent-2 text-darken-4">Plural:</span> {{word.data.feminine}}  </p>
        <p><span class="blue-text text-accent-2 text-darken-4">Plural:</span> {{word.data.plural}}  </p>
      </div>


    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "WordView",
  props: {
    word: {
      type: Object,
      default() {
        return {
          id: 556,
          word: 'sayyára (n) (p)sayyárát(!p)',

        }
      }
    }
  },
  methods: {

    getWordTitle(word) {
      if (!word.type) return '?'
      if (word.type === 'noun') {
        return word.data.singular
      }
      if (word.type === 'verb') {
        return word.data.infinitive + ' ' + word.data.suffixSentence
      }
      if (word.type === 'adjective') {
        return word.data.masculine
      }
      return word.word
    },
  }
}
</script>

<style scoped>

</style>