<template>
<div v-if="displayedArray">


  <div class="row" >



    <div class="col s12 m6 l4"  v-for="(conjList, index) in displayedArray" :key="index">
      <div class="card grey lighten-4">
        <div class="card-content">
        <div class="card-title truncate">
          {{ conjList[0].replace('_', ' ') }}
        </div>
          <div class="divider" style="margin-bottom: 1em"></div>


          <table class="striped" >
            <tr style="border-style: none; " v-for="(conjugation, index) in Object.entries(conjList[1])" :key="index">
              <td style="padding: 0; width: 4.5em; ">{{ conjugation[0] }}</td>
              <td style="padding: 0;" nowrap="">{{ conjugation[1] }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>



  </div>


</div>
</template>

<script>
export default {
  name: "ConjugationView",
  props: {
    verb: {
      type: Array,
      default() {
        return [
          [ "PresentSimple", { "ana": "brú7", "ente": "betrú7", "enti": "betrú7i", "huwe": "birú7", "hiye": "betrú7", "ne7ne": "menrú7", "entu": "betrú7u", "henne": "birú7u" } ]
        ]
      }
    }
  },
  computed: {
    displayedArray() {
      return this.verb
    }
  }
}
</script>



<style scoped>

</style>