<template>
<div  class="container " >

  <div class="row" style="margin-bottom: 0; margin-top: 2em">


    <div class="col s12 grey lighten-4 card " style="border-radius: 0.5em">
      <div class="card-content" style="  padding: 0;">


        <div class="btn-floating btn-switch z-depth-0" @click="switchLanguages"> <i class="material-icons blue-grey-text">swap_horiz</i></div>
          <div class="row" style="padding-top: 1em" >
            <div class="col s6" style="user-select: none"> {{ ArabicAlphabet? 'English':  sourceLanguage }} </div>
            <div class="col s6" style="user-select: none"> {{ArabicAlphabet?  'Syrian' :  targetLanguage }} </div>
          </div>


<!--        <div class="row black" style="min-height: 1px; margin-bottom: 0" ></div>-->

<!--        Translation Fields-->


        <div v-if="ArabicAlphabet && sourceLanguage.toLowerCase() === 'syrian'" class="col s12 m6  left-align" style="font-size: 1.5em">
          <!--              English words-->
          <ol v-if="targetLanguage.toLowerCase() === 'english'" >
            <li v-for="word in translatedWords" :key="word.id">
              {{ word.word}}
            </li>
          </ol>
          <!--              Syrian words-->
          <ol v-if="targetLanguage.toLowerCase() === 'syrian'" >
            <li v-for="word in translatedWords" :key="word.id">
              {{ getWordTitle(word)}}
            </li>
          </ol>
        </div>


<!--        TRANSLATE AREA-->
        <div class="row  " style="padding: 0; margin-bottom: 0">
          <div class="col s12 m6 maxCol" style="padding: 0;">
            <TranslateArea v-model="textToTranslate" style="height: 100%">
              <div class="btn grey" @click="translate">Translate</div>
            </TranslateArea>
          </div>



          <div v-if="!(ArabicAlphabet && sourceLanguage.toLowerCase() === 'syrian')" class="col s12 m6  left-align" style="font-size: 1.5em">
            <!--              English words-->
            <ol v-if="targetLanguage.toLowerCase() === 'english'" >
              <li v-for="word in translatedWords" :key="word.id">
                {{ word.word}}
              </li>
            </ol>
            <!--              Syrian words-->
            <ol v-if="targetLanguage.toLowerCase() === 'syrian'" >
              <li v-for="word in translatedWords" :key="word.id">
                {{ getWordTitle(word)}}
              </li>
            </ol>
          </div>


        </div>
      </div>
    </div>










  </div>


  <div class="row left-align" v-if="suggestedWords">
    Words:
    <div class="waves-effect btn-flat wordOption" v-for="(word, index) in suggestedWords" :key="index"
    @click="selectWord(word)">
      {{  getWordTitle(word) }} <i class="wordType">({{word.type}})</i>
    </div>

  </div>

  <WordView :word="selectedWord"></WordView>


<Conjugation  :verb="Object.entries(conjugation)" ></Conjugation>


<!--  <div  v-for="(item, index) in Object.entries(conjugation) " :key="index">{{ item }}</div>-->




</div>
</template>












<script>
import TranslateArea from "../components/Controls/TranslateArea";
import Conjugation from "../components/Conjugation";
import WordView from "../components/WordView";

export default {
  name: "Translate",
  components: {TranslateArea, Conjugation, WordView},
  computed: {
    translatedWords(){
      return this.$store.state.translate.translatedWords
    },
    suggestedWords(){
      return this.$store.state.translate.suggestedWords
    },
    conjugation(){
      return this.$store.state.translate.conjugation
    },
    selectedWord() {
      return this.$store.state.translate.selectedWord
    }
  },
  data() {
    return {
      textToTranslate: "Hello",
      sourceLanguage: "English",
      targetLanguage: "Syrian",
      ArabicAlphabet: false
    }
  },
  methods: {
    // Syrian or English?
    // Syrian => Word unless the API returns empty list

    translate() {
      if ( this.textToTranslate.trim() === '') return;
      if (this.sourceLanguage.toLowerCase() === 'syrian'){
        this.$store.dispatch("lookUpSyrian", this.textToTranslate.trim())
      }
      else
      {
        this.$store.dispatch("translateWordToSyrian", this.textToTranslate.trim())
        // console.log("hi")
      }
    },
    getWordTitle(word) {
      if (!word.type) return '?'
      if (word.type === 'noun') {
        return word.data.singular
      }
      if (word.type === 'verb') {
        return word.data.infinitive + ' ' + word.data.suffixSentence
      }
      if (word.type === 'adjective') {
        return word.data.masculine
      }
      return word.word
    },
    selectWord(word) {
      this.$store.dispatch("selectSyrianWord", {word: word, translate: this.targetLanguage.toLowerCase() === 'english'})

    },
    switchLanguages(){
      // to manage the view, first make sure that the elements are well shaped
      if(this.sourceLanguage.toLowerCase() === 'syrian'){
        if(this.translatedWords.length > 0) this.textToTranslate = this.translatedWords[0].word
        else this.textToTranslate = ''
      }
      else {
        if(this.translatedWords.length > 0) this.textToTranslate = this.getWordTitle( this.translatedWords[0] )
        else this.textToTranslate = ''
      }
      const temp = this.sourceLanguage
      this.sourceLanguage = this.targetLanguage
      this.targetLanguage = temp
      this.translate()
    },
  }
}
</script>





















<style >
.wordOption {
  text-transform: none
}

.wordOption:hover {
  background-color: #f4f4f4;
}

.wordType {
  color: #0077a3;
  font-size: inherit !important;
}

.btn-switch {
  position: absolute;
  background-color: transparent;
  left: calc(50% - 20px);
  top: 8px;
  user-select: none;
}

.btn-switch:hover {
  background-color: #dadada;
}

.maxCol {
  min-height: 100% !important;
}
</style>