<template>
<div class="mus-translate-field">
<!--  <label for="translateArea" style="visibility: hidden">Enter Text To Translate</label>-->
  <textarea ref="translateInput" class="mus-textarea" v-model="bufferValue" name="translateArea" id="translateArea" cols="30" rows="4" @input="updateValue"></textarea>
  <div class="translate-back"></div>
  <div class="mus-translate-panel">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
name: "TranslateArea",
  props: {
    value: {
      type: String,
      default() {
        return ''
      }
    },
  },
  watch: {
    value(newValue) {
      this.bufferValue = newValue
    }
  },
  data() {
    return {
      bufferValue: ''
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.$refs.translateInput.value)
    },
    setValue(value) {
      this.bufferValue = value
    },
  }
}
</script>

<style scoped>
.mus-translate-field {
  --label-bg-color: #076fde;
  position: relative;
  margin: 0;
  padding: 0;
  height: 50%;
  /*min-height: 100%;*/
  /*background-color: #42b983;*/
}

.translate-back {
  position: absolute;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-style: none;
  border-color: black;
  border-width: 0.5px;
  border-radius: 0 0 0.5em 0.5em;
  transition: 0.2s;
}


.mus-textarea {
  position: relative;
  outline-style: none;
  border-style: none;
  border-width: 0;
  padding: 0.3em 0.3em 0.3em 0.3em;
  resize: none;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  z-index: 1;
}

textarea:hover + .translate-back,
textarea:focus + .translate-back {
  border-style: solid;
  border-color: var(--label-bg-color);
  border-width: 1px;
}

.mus-translate-panel {
  position: relative;
  z-index: 2;
}

</style>